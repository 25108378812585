<div class="h-100">
  <mwc-spin [mwcSpinning]="isLoading" class="global-loader">
    <router-outlet></router-outlet>
  </mwc-spin>
</div>
<ng-template #templateTitle>
  <div style="display: flex;align-items: center;gap: 16px;">
    <div class="mwc-upload-text-icon mwc-icon-highlight-primary-lg">
      <span mwc-icon mwcIconfont="cursor-box" style="font-size: 20px"></span>
    </div>
    <div>
      <div class="mwc-text-lg-medium mwc-color-gray-900">Select a Template to Get Started </div>
      <span class="mwc-text-sm-regular mwc-color-gray-600">Generate SEO-friendly and contextually-rich content.</span>
    </div>
  </div>
</ng-template>

