
import { MenuService } from "mwc/menu";
import { ImageRenderingModel } from "./imagerenderingmodel";
import { ImageTransformations } from "./imagetransformations";

export class DamAssetInfo {
  OriginalUrl?: string;
  ThumbnailUrl?: string;
  PreviewUrl?: string;
  LargeUrl?: string;
  MediumUrl?: string;
  SmallUrl?: string;
  ImageTransformations?: ImageTransformations;
  ManualMeta?: ManualMeta

  constructor(
    _originalUrl?: string,
    _thumbnailUrl?: string,
    _previewUrl?: string,
    _largeUrl?: string,
    _mediumUrl?: string,
    _smallUrl?: string,
    _imageTransformations?: ImageTransformations,
    _manualMeta?: ManualMeta


  ) {
    this.OriginalUrl = _originalUrl;
    this.ThumbnailUrl = _thumbnailUrl;
    this.PreviewUrl = _previewUrl;
    this.LargeUrl = _largeUrl;
    this.MediumUrl = _mediumUrl;
    this.SmallUrl = _smallUrl;
    this.ImageTransformations = _imageTransformations;
    this.ManualMeta = _manualMeta;

  }

}
export class ManualMeta {
  Description: string;
  Title: string;
}
