import { Guid } from "guid-typescript";
import { DamAssetInfo } from "./damassetinfo";
export class DamAssetModel {
  AssetId?: Guid;
  AssetName: string;
  AssetAlias: string;
  FolderId: number;
  StockImage: boolean;
  DamAssetInfo: DamAssetInfo
  constructor(
    _assetId?: Guid,
    _assetName?: string,
    _assetAlias?: string,
    _folderId?: number,
    _stockImage?: boolean,
    _damAssetInfo?: DamAssetInfo

  ) {
    this.AssetId = _assetId;
    this.AssetName = _assetName;
    this.AssetAlias = _assetAlias;
    this.FolderId = _folderId;
    this.StockImage = _stockImage;
    this.DamAssetInfo = _damAssetInfo;
  }


}
