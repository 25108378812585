<div class="filter-sec">
  <div>
    <div class="mwc-text-sm-medium">Intent</div>
    <div class="intent">
      <mwc-radio-group [(ngModel)]="intentValue" mwcName="radiogroup">
        <label mwc-radio mwcValue="all">All</label>
        <label mwc-radio *ngFor="let data of widgetData.intents"  mwcValue="{{data}}" style="text-transform: capitalize;">{{data}}</label>
        <!--<label mwc-radio mwcValue="informational">Informational</label>
        <label mwc-radio mwcValue="commercial">Commercial</label>
        <label mwc-radio mwcValue="transactional">Transactional</label>-->
      </mwc-radio-group>
    </div>
  </div>
  <!--<div>
    <div class="mwc-text-sm-medium">Target Audience</div>
    <div class="target-audience-search">
      <mwc-input-group [mwcPrefix]="prefixIconSearch">
        <input mwc-input type="text" [(ngModel)]="searchTxt" (keyup)="triggerSearch()" placeholder="Search" mwcSize="small" />
      </mwc-input-group>
      <ng-template #prefixIconSearch>
        <span mwc-icon mwcIconfont="search-lg" style="font-size: 18px; color: #667085;"></span>
      </ng-template>
    </div>
    <div class="target-audience">
      <div class="check-all">
        <label mwc-checkbox
               [(ngModel)]="allChecked"
               (ngModelChange)="updateAllChecked()"
               [mwcIndeterminate]="indeterminate">
           All
        </label>
      </div>
      <mwc-checkbox-group [(ngModel)]="targetAudienceList" (ngModelChange)="targetAudienceListChange(targetAudienceList)"></mwc-checkbox-group>
    </div>
  </div>
</div>-->
  <div class="filter-action">
    <div><button mwc-button mwcSize="small" style="margin-right: 8px;" mwcType="link-color" (click)="clearFilter()">Clear</button></div>
    <div>
      <button mwc-button mwcSize="small" style="margin-right: 8px;" (click)="close()">Cancel</button>
      <button mwc-button mwcSize="small" mwcType="primary" (click)="apply()">Apply</button>
    </div>
  </div>
