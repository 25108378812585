export class GenerateArticleByAIRequestModel{
    Context: string = "";
    InputContext: string = "";
    InputTone: string = "";
    InputLanguage: string = "";
    Mode: string = "";
    Section: string = "";
    FieldId: string = "";
    AiEngine: string = "";
}
export interface GenerateArticleByAIRequestModel{
    Context: string;
    InputContext: string;
    InputTone: string;
    InputLanguage: string;
    Mode: string;
    Section: string;
    FieldId: string;
    AiEngine: string;
}