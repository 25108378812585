import {
  Directive,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Directive({
  selector: '[appImageHandler]',
  host: {
    '(error)': 'handleError()',
    '[attr.src]': 'imageUrl',
  },
})
export class ImageHandlerDirective implements OnInit, OnChanges {
  @Input()
  targetImageUrl: string = '';

  @Input()
  defaultImageUrl: string = '';

  imageUrl = '';

  constructor() {}

  ngOnInit(): void {
    const defaultImage = new Image();
    defaultImage.src = this.defaultImageUrl;
    this.imageUrl = this.defaultImageUrl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'targetImageUrl') {
        const targetImage = new Image();
        targetImage.onload = () => {
          this.imageUrl = this.targetImageUrl;
        };
        targetImage.src = changes[propName].currentValue;
      }
    }
  }

  handleError() {
    this.imageUrl = this.defaultImageUrl;
  }
}
