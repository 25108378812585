import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MwcMessageService } from 'mwc/message';
import { MwcModalRef, MwcModalService, NZ_MODAL_DATA } from 'mwc/modal';
import { ErrorModalOrigins } from 'src/app/_shared/consts/article-constants';
import { TranslationService } from 'src/app/_shared/services/utility/translation/translation.service';

@Component({
  selector: 'app-article-ai-generation-loading-modal',
  templateUrl: './article-ai-generation-loading-modal.component.html',
  styleUrls: ['./article-ai-generation-loading-modal.component.scss']
})
export class ArticleAiGenerationLoadingModalComponent {
  readonly mwcModalData = inject(NZ_MODAL_DATA);
  generationType: string = "";
  constructor(private modalService: MwcModalService, private modal: MwcModalRef, 
    private router: Router, private translation: TranslationService) { }
  
  ngOnInit() {
    // change the text of an modal based on where it is coming from
    this.changeGenerationType(this.mwcModalData.modalOrigin);
  }
  
  public hideErrorPopup() {
    this.modal.destroy();
  }

  public changeGenerationType(modalOrigin: any) {
    switch (modalOrigin) {
      case ErrorModalOrigins.FROM_KEYPHRASE_GENERATION_MODAL:
        this.generationType = $localize`:@@bs-ai-generation-type-keyphrase-text:Keyphrase`;
        break;
      case ErrorModalOrigins.FROM_GKP_MODAL:
        this.generationType = '';
        break;
      case ErrorModalOrigins.FROM_TOPIC_IDEA_AI_GENERATION_MODAL:
        this.generationType = '';
        break;
      case ErrorModalOrigins.FROM_TOPIC_IDEA_AI_OUTLINE_GENERATION_MODAL:
        this.generationType = 'Outline';
        break;
      case ErrorModalOrigins.DEFAULT:
        // TODO: if we implement in future
        break;
      default:
        break;
    }
  }


}
