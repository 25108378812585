import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { DamComponent } from './components/dam/dam.component';

import { MscModule, MscAssetBrowserModule, MscService } from 'msc';
import { MwcEmptyStateComponent } from './components/mwc-empty-state/mwc-empty-state.component';
import { MwcButtonModule } from 'mwc/button';
import { MwcIconModule } from 'mwc/icon';
import { MwcOutletModule } from 'mwc/core/outlet';
import { MwcSpinModule } from 'mwc/spin';
//import { FolderListService } from 'msc/lib/modules/asset-browser/services/folder-list.service';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    InformationDialogComponent,
    DamComponent,
    MwcEmptyStateComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MscModule,
    MscAssetBrowserModule,
    MwcButtonModule,
    MwcIconModule,
    MwcOutletModule,
    MwcSpinModule
  ],
  providers: [
    MscService,
    //FolderListService,
  ],
  exports: [ConfirmationDialogComponent, DamComponent,MwcEmptyStateComponent],
})
export class SharedUiModule {}
