<div class="parent-modal">
    <div class="empty-status-body">
        <div class="empty-status">
          <div class="empty-status-msg">
            <div class="image-center">
                <mwc-spin mwcSimple ></mwc-spin>
            </div>
            <div>
              <!-- <div class="mwc-text-lg-semibold mwc-color-gray-900">Oops, Something Went Wrong!</div> -->
              <span i18n="@@bs-ai-generation-loading-text" mwc-typography class="mwc-text-sm-regular">{{generationType}} Generation is in progress, it may take few seconds...</span>
            </div>
          </div>
        </div>
    </div>
</div>