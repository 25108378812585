import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HelperService } from 'src/app/_shared/services/helper/helper.service';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { lastValueFrom, Observable } from 'rxjs';
import { SiteConfig, PageCategory, GetPageCategoryResponse, GetAllBusinessCategoriesResponse, GetAllBusinessCategoriesRequest } from 'src/app/_shared/models/cms'

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  // Private Properties  

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private _storageHelper: StorageHelper;
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private helperService: HelperService,
  ) {
    this._storageHelper = new StorageHelper();
  }

  //get cms site configuration
  public async getCmsSiteConfig<T>(): Promise<SiteConfig> {
    try {
      // Check if siteConfig exists in sessionStorage
      const siteConfigString = sessionStorage.getItem('siteConfig');
      if (siteConfigString) {
        return JSON.parse(siteConfigString);
      }
      // Fetch siteConfig from API if not found in sessionStorage
      const siteConfig = await lastValueFrom(this.http.post<SiteConfig>(
        `${this.baseUrl}api/cmscontent/getCmsSiteConfiguration`,
        {},
        { headers: this.headers }));

      // Store the fetched siteConfig in sessionStorage
      sessionStorage.setItem('siteConfig', JSON.stringify(siteConfig));
      return siteConfig;
    } catch (err) {
      console.log(err);
      return;
    }
  }

  public async getPageCategoryList<T>(): Promise<PageCategory[]> {
    try {
      var data = await lastValueFrom(this.http.get<GetPageCategoryResponse>(
        `${this.baseUrl}api/cmscontent/GetPageCategoryList`));

      if (data && data.errorCode == 0) {
        this._storageHelper.siteModule = data.pageCategories;
      }
    } catch (err) {
      console.log(err);
      return;
    }
    return this._storageHelper.siteModule;
  }



  //get article list
  public GetAllBusinessCategories<T>(): Observable<GetAllBusinessCategoriesResponse> {
    return this.http.post<GetAllBusinessCategoriesResponse>(
      `${this.baseUrl}api/CmsContent/getallbusinesscategories`,
      {},
      { headers: this.headers }
    );
  }


}
