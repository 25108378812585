import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './_shared/http-interceptors';
import { MscModule, MscAssetBrowserModule, MscService } from 'msc';
import { SharedUiModule } from './_shared/modules/shared-ui/shared-ui.module';
import { HelperModule } from './_shared/modules/helper/helper.module'
import { LayoutModule } from './layout/layout.module';
import { MwcButtonModule} from 'mwc/button';
import { MwcInputModule } from 'mwc/input';
import { MwcCheckboxModule} from 'mwc/checkbox';
import { MwcIconModule } from 'mwc/icon';
import { MwcMenuModule } from 'mwc/menu'
import { MwcSpinModule } from 'mwc/spin';
import { GenarateAiComponent } from './genarate-ai-modal/genarate-ai.component';
import { MwcModalModule } from 'mwc/modal';
import { MwcSpaceModule } from 'mwc/space';
import { MwcFormModule } from 'mwc/form';
import { MwcSelectModule } from 'mwc/select';
import { MwcSwitchModule } from 'mwc/switch';
import { MwcDividerModule } from 'mwc/divider';
import { MwcTagModule } from 'mwc/tag';
import { SecondaryFilterComponent } from './secondary-filter/secondary-filter.component';
import { MwcDrawerModule } from 'mwc/drawer';
import { MwcRadioModule } from 'mwc/radio';
import { MwcMessageModule } from 'mwc/message';
import { MwcToolTipModule } from 'mwc/tooltip';
import { TemplateSelectorModule } from './_shared/modules/shared-ui/components/template-selector/template-selector.module';
import { MwcTabsModule } from 'mwc/tabs';
import { MwcTypographyModule } from 'mwc/typography';
import { MwcAlertModule } from 'mwc/alert';

@NgModule({
  declarations: [AppComponent, GenarateAiComponent, SecondaryFilterComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    HelperModule,
    SharedUiModule,
    MatDialogModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDatepickerModule,
    MatChipsModule,
    MatPaginatorModule,
    MscModule,
    MscAssetBrowserModule,
    LayoutModule,
    MwcButtonModule,
    MwcInputModule,
    MwcCheckboxModule,
    MwcIconModule,
    MwcMenuModule,
    MwcSpinModule,
    MwcModalModule,
    MwcSpaceModule,
    ReactiveFormsModule,
    MwcFormModule,
    MwcSelectModule,
    MwcSwitchModule,
    MwcDividerModule,
    MwcTagModule,
    MwcDrawerModule,
    MwcRadioModule,
    MwcMessageModule,
    MwcToolTipModule,
    TemplateSelectorModule,
    MwcTabsModule,
    MwcTypographyModule,
    MwcAlertModule
  ],
  providers: [
    httpInterceptorProviders,
    //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    CookieService,
    MscService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
