import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, catchError, filter, lastValueFrom, tap, throwError } from 'rxjs';
import { LOGIN_COOKIE_NAME } from 'src/app/_shared/consts/app-constants';
import { LoginResponse, User, VerifyUserRequest } from 'src/app/_shared/models/common';
import { MixpanelService } from 'src/app/_shared/services/mixpanel/mixpanel.service';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { environment } from 'src/environments/environment';
declare global {
  interface Window {
    mixpanel: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _storageHelper: StorageHelper;
  mixpanelTrackingId: string = '';
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private mixpanelService: MixpanelService,
  ) {
    this._storageHelper = new StorageHelper();
  }

  async isAuthenticated() {
    try {
      if (!this._storageHelper.user.success) {
        const user = await this.VerifyUser();
        this.setVerifiedUser(user);
        this.router.navigate([`dashboard`]);
        await this.getMixpanelInfo();
        return true;
      }
      return true;
    } catch (err) {
      console.log(err);
    }
    this._storageHelper.clear();
    return false;
  }

  private async VerifyUser(): Promise<User> {
    let request = new VerifyUserRequest();
    request.businessId = +this._storageHelper.user.bId;
    request.profileAlias = this._storageHelper.user.profileAlias;
    const user = await lastValueFrom(
      this.http.post<User>(`${this.baseUrl}api/auth/verifyuser`, request)
    );
    return user;
  }


  async setVerifiedUser(user: User) {
    this._storageHelper.user = user;
  }

  public async ApplyProfile(profileId: number): Promise<boolean> {
    try {
      const user = await lastValueFrom(
        this.http.post<User>(`${this.baseUrl}api/auth/applyprofile?profileId=` + profileId, {})
      );
      if (user && user.success) {
        this.setVerifiedUser(user);
        return true;
      }
    } catch (err) {
      console.log(err);
    }
    this._storageHelper.clear();
    return false;
  }

  routeToBase() {
    const redirectUrl = this._storageHelper.user ? this._storageHelper.user.returnUrl : "";
    this.router.navigate(['auth'], {
      queryParams: { redirectUrl: redirectUrl },
    });
  }

  keepAlive() {
    try {
      if (!this.baseUrl) return;
      this.http
        .post(
          `${this.baseUrl}api/auth/keepalive`,
          null
        )
        .subscribe();
    } catch (error) {
      console.error('Unable to Keep Alive');
    }
  }

  private async getMixpanelInfo() {
    if (!this.mixpanelTrackingId || this.mixpanelTrackingId === '') {
      const result = this.mixpanelService.getMixpanelId();
      const trackingId = await lastValueFrom(result);
      if (trackingId) {
        this.mixpanelTrackingId = trackingId.mixpanelTrackingId;
        window.mixpanel.init(this.mixpanelTrackingId);
      }
    }
  }

  private _expiresIn = new BehaviorSubject<number>(null);
  //Token for server access.
  public get expiresInSubject(): Observable<number> {
    return this._expiresIn;
  }

  public set expiresIn(expiresIn: number) {
    this._expiresIn.next(expiresIn);
  }

  public get expiresIn() {
    return this._expiresIn.value;
  }

  public istokenExpired(): boolean {
    let expires_in = new Date(this.expiresIn);
    let expires_inUTC = new Date(
      Date.UTC(
        expires_in.getUTCFullYear(),
        expires_in.getUTCMonth(),
        expires_in.getUTCDate(),
        expires_in.getUTCHours(),
        expires_in.getUTCMinutes(),
        expires_in.getUTCSeconds()
      )
    );
    expires_inUTC.setMinutes(expires_inUTC.getMinutes() - 10);
    var currrenttime = new Date();
    let currrenttimeUTC = new Date(
      Date.UTC(
        currrenttime.getUTCFullYear(),
        currrenttime.getUTCMonth(),
        currrenttime.getUTCDate(),
        currrenttime.getUTCHours(),
        currrenttime.getUTCMinutes(),
        currrenttime.getUTCSeconds()
      )
    );
    return expires_inUTC < currrenttimeUTC;
  }

  private _token = new BehaviorSubject<string>(null);
  //Token for server access.
  public get tokenSubject(): Observable<string> {
    return this._token;
  }

  public set token(jwtToken: string) {    
    this._token.next(jwtToken);
    this._isTokenAuthenticated.next(jwtToken != null);
  }

  public get token() {
    return this._token.value;
  }

  //Notification that authentication info has been set
  //and we can start making API calls that require authenticated client.
  private _isTokenAuthenticated = new BehaviorSubject<boolean>(false);

  public get isTokenAuthenticatedSubject(): Observable<boolean> {
    return this._isTokenAuthenticated;
  }

  public get isTokenAuthenticated(): boolean {
    return this._isTokenAuthenticated.value;
  }

  public markAuthenticated(): void {
    this._isTokenAuthenticated.next(true);
  }

  public getNewAuthToken() {
    console.log('getNewAuthToken');
    let currentdate = new Date();
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http
      .post<LoginResponse>(this.baseUrl + 'Authentication/getnewtoken', '', {
        withCredentials: true,
        headers: header,
      })
      .pipe(
        tap((response) => {
          if (response) {
            //When SSO user has only 1 profile access and destination is CMS
            if (response.token_type != null && response.token_type == 'REDIRECT_TO_CMS') {
              window.open(response.url, "_self");
            } else {
              this.processLoginResponse(response, false);
            }
          }
        }),
        catchError((error) => {
          return throwError(false);
        })
      );
  }

  private processLoginResponse(response: LoginResponse, rememberMe: boolean) {
    let currentdate = new Date();
    let accessToken = response.access_token;
    if (accessToken == null) console.log('Received accessToken was empty');
    if (response.errorcode == 0) {
      if (response.access_token != '') {
        this.token = response.access_token;
        this.expiresIn = currentdate.setUTCSeconds(currentdate.getUTCSeconds() + response.expires_in);
        this.markAuthenticated();
      } else {
        this.token = '';
        this.expiresIn = 0;
      }
    }
    return response;
  }

}
