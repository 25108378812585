import { ImageRenderingModel } from "./imagerenderingmodel";
export class ImageTransformations {
  Large?: ImageRenderingModel;
  Medium?: ImageRenderingModel;
  Small?: ImageRenderingModel;
  constructor(
    _large?: ImageRenderingModel,
    _medium?: ImageRenderingModel,
    _small?: ImageRenderingModel

  ) {
    this.Large = _large;
    this.Medium = _medium;
    this.Small = _small;
  }


}
