<div mat-dialog-content>
  Are you sure you want to {{ data.actionName.toLowerCase() }} {{ data.entityName }}?
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    [color]="data.isWarn ? 'warn' : 'primary'"
  >
    {{ data.actionName }}
  </button>
</div>
