import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { MwcMessageService } from 'mwc/message';
import { MwcModalRef, MwcModalService } from 'mwc/modal';
import { ArticleStoreService } from 'src/app/services/article/article-store.service';
import { ArticleService } from 'src/app/services/article/article.service';
import { SpinnerDialogConfig } from 'src/app/_shared/consts/dialog-config';
import { Article, CustomArticleErrorModel } from 'src/app/_shared/models/article';
import { GoogleKeywordPlannerResponseType, GoogleKeywordResultType } from 'src/app/_shared/models/google-keyword-planner';
import { DialogService } from 'src/app/_shared/services/dialog/dialog.service';
import { G2MiniAreaData } from 'mwc/chart/mini-area';
import { format } from 'date-fns';
import { ErrorModalOrigins, GKPToolTips, KeywordConstraint } from 'src/app/_shared/consts/article-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { ArticleErrorModalComponent } from '../_shared/modals/article-error-modal/article-error-modal.component';
import { ArticleHelperService } from 'src/app/_shared/services/helper/article/article-helper.service';
export interface Data {
  id: number;
  keyPhrase: string;
  searchVolume: string;
  trend: G2MiniAreaData[];
  competition: string;
}
@Component({
  selector: 'app-select-your-keyword',
  templateUrl: './select-your-keyword.component.html',
  styleUrls: ['./select-your-keyword.component.scss']
})
export class SelectYourKeywordComponent implements OnInit {
  @Output() confirmEmitter: EventEmitter<string[]> = new EventEmitter();
  /* Table */
  checked = false;
  indeterminate = false;
  listOfData: Data[] = [];
  listOfCurrentPageData: readonly Data[] = [];
  setOfCheckedId = new Set<number>();
  isLoading = false;
  GKPToolTips: any = GKPToolTips
  selectedKeyphraseValues: Array<{ label: string; value: string; checked?: boolean; }> = [];
  selectedKeywords: Data[] = [];
  existingKeywordCount: number;
  maxAllowedKeywordSelection: number;
  existingKeyword: string;
  constructor(private modal: MwcModalRef, private articleService: ArticleService, private modalService: MwcModalService,
    private articleStoreService: ArticleStoreService, private router: Router, private msg: MwcMessageService,
    private dialogService: DialogService, private articleHelperService: ArticleHelperService) { }

  ngOnInit(): void {
    const customError: CustomArticleErrorModel = {
      ErrorHeading: $localize`:@@bs-select-your-keyword-gkp-error-heading-text:Please Review Your Prompt`,
      ErrorSubHeading: $localize`:@@bs-select-your-keyword-gkp-error-subheading-text:The system is unable to find relevant keyphrases based on the prompt you have selected. Kindly try again with a different prompt.`
    }
    try {
      this.selectedKeywords = [];
      this.existingKeyword = JSON.parse(JSON.stringify(this.modal.getConfig().mwcData.keywords))
      this.existingKeywordCount = JSON.parse(JSON.stringify(this.modal.getConfig().mwcData.keywords.length));
      this.maxAllowedKeywordSelection = KeywordConstraint.MAX_KEYWORDS - this.existingKeywordCount;
      // Keyphrase Data
      let keywordDataFromGoogle: GoogleKeywordPlannerResponseType = JSON.parse(JSON.stringify(this.modal.getConfig().mwcData.keywordResponse));
      if (keywordDataFromGoogle?.results?.length > 0) {
        keywordDataFromGoogle.results.forEach((element, index) => {
          let data = {
            id: index + 1,
            keyPhrase: element.text,
            searchVolume: element.keywordIdeaMetrics?.avgMonthlySearches,
            trend: this.generateTrendDataForGKP(element, index),
            competition: element.keywordIdeaMetrics?.competition
          }
          this.listOfData.push(data);
          this.selectedKeyphraseValues.push({ label: data.keyPhrase, value: data.keyPhrase, checked: false });
        });
        //this.selectedKeyphraseValue = this.listOfData[0].keyPhrase;
      } else {
        throw new Error("No data found..")
      }
    } catch (error) {
      this.listOfData = [];
      console.log(error);
      // this.msg.error(error,{ mwcAnimate: true,mwcDuration: 10000});
      // show error modal if kyephrase generation is failed
      this.articleHelperService.showErrorModal(this.modalService, this.modal, ErrorModalOrigins.FROM_KEYPHRASE_GENERATION_MODAL, error, customError)
    }
  }

  private generateTrendDataForGKP(currentElement: GoogleKeywordResultType, index: number): G2MiniAreaData[] {
    //loop over monthlySearchVolumes and apply x as year and y as searches number
    const graphPoints: G2MiniAreaData[] = [];
    currentElement.keywordIdeaMetrics?.monthlySearchVolumes.forEach((element, index) => {
      graphPoints.push({
        x: index + element.year,
        y: element?.monthlySearches,
      });
    })
    return graphPoints;
  }

  cancelSelection() {
    this.selectedKeywords = [];
    this.modal.destroy();
  }

  addKeywords() {
    this.confirmEmitter.emit(this.selectedKeywords.map(k => k.keyPhrase));
  }

  keywordSelectionChange(data, isSelected): void {
    if (isSelected.length > 0) {
      this.selectedKeywords.push(data);
    }
    else {
      this.selectedKeywords = this.selectedKeywords.filter(x => x.id !== data.id);
    }
  }

  getTotalKeywords() {
    return this.existingKeywordCount + this.selectedKeywords.length;
  }

}
