import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageHandlerDirective } from './directives/image-handler.directive';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  declarations: [SafePipe, ImageHandlerDirective],
  imports: [CommonModule],
  exports: [SafePipe, ImageHandlerDirective],
})
export class HelperModule {}
