import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { AuthGuard } from './_shared/guards/auth.guard';
import { AuthGuard } from './_shared/guards/auth.guard';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'manage-content',
    component: BasicLayoutComponent,
    data: {
      breadcrumb: 'Manage Content',
      breadcrumb_group : true
    },
    children: [
      {
        path: 'articles',
        loadChildren: () => import('./manage-content/articles/article.module').then(m => m.ArticleModule),
        data: {
          breadcrumb: 'Articles'
        }
      },
      { path: '**', redirectTo: 'articles',  pathMatch: 'full' }
    ],
    canActivate: [AuthGuard]
  },
  //{ path: 'settings',
  //  component: BasicLayoutComponent,
  //  data: {
  //    breadcrumb: 'Settings'
  //  },
  //  loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
  //  canActivate: [AuthGuard]
  //},
  { path: 'templates',
    component: BasicLayoutComponent,
    data: {
      breadcrumb: 'Templates'
    },
    loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'topic-ideas', 
    component: BasicLayoutComponent,
    loadChildren: () => import('./topic-ideas/topic-ideas.module').then(m => m.TopicIdeasModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'dashboard', 
    component: BasicLayoutComponent,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
   canActivate: [AuthGuard]
  },
  { 
    path: 'brand', 
    component: BasicLayoutComponent,
    loadChildren: () => import('./brand/brand.module').then(m => m.BrandModule),
   canActivate: [AuthGuard]
  },
  { 
    path: 'brand', 
    component: BasicLayoutComponent,
    loadChildren: () => import('./brand/brand.module').then(m => m.BrandModule),
   canActivate: [AuthGuard]
  },
  { 
    path: 'target-audience', 
    component: BasicLayoutComponent,
    loadChildren: () => import('./target-audience/target-audience.module').then(m => m.TargetAudienceModule),
   canActivate: [AuthGuard]
  },
  { 
    path: 'settings', 
    component: BasicLayoutComponent,
    loadChildren: () => import('./content-settings/content-settings.module').then(m => m.ContentSettingsModule),
   canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
