import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProfileSelectService {
  selectedProfileId: Set<number> = new Set();
  public selectedProfileId$: BehaviorSubject<Set<number>> = new BehaviorSubject(new Set());


  public get getSelectedProfileId(): any {
    return this.getSelectedProfileId.value;
  }

  public setSelectedProfileId(selectedProfileId: any) {
    this.selectedProfileId = selectedProfileId;
    this.selectedProfileId$.next(this.selectedProfileId);
  }
  constructor() { }
}
