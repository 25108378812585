<div *mwcModalTitle>
  <div mwc-row [mwcGutter]="16" style="flex-flow: row;">
    <div mwc-col>
      <div class="mwc-icon-highlight-primary-lg">
        <span mwc-icon mwcIconfont="cursor-box"></span>
      </div>
    </div>
    <div mwc-col>
      <div i18n="@@bs-select-your-keyword-heading-text" class="mwc-text-lg-semibold mwc-color-gray-900">Select Keywords</div>
      <div mwc-row [mwcGutter]="16" [mwcJustify]="'space-between'" [mwcAlign]="'bottom'" style="flex-flow: row;">
        <div mwc-col>
          <span mwc-typography mwcType="secondary" class="mwc-text-sm-regular" style="display: block;">
            We found some keywords you can target based on their trending organic search performance.
            You can select upto {{maxAllowedKeywordSelection}} keywords at a time.
          </span>
        </div>
        <div mwc-col style="width: 200px;text-align: right;">
          <span class="mwc-text-sm-medium" style="color: #474D66;">{{getTotalKeywords()}}/6 Selected</span>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #GKPToolTip let-tooltip>
  <span style="display: inline-flex;align-items: center;margin-left:5px">{{tooltip}}</span>
</ng-template>
<div mwc-row [mwcGutter]="[0, 32]">
  <div mwc-col [mwcSpan]="24">
    <mwc-table #rowSelectionTable [mwcPageSize] = "20" [mwcScroll]="{ y: 'calc(100vh - 400px)' }" [mwcShowPagination]="false" [mwcData]="listOfData" mwcClassName="blog-post-table-body overflow-auto" class="blog-post-table" mwcTableLayout="fixed" [mwcWidthConfig]="['300px','160px','100px','150px']">
      <thead>
        <tr>
          <th i18n="@@bs-select-your-keyword-table-col-th-keyphrase-text" style="width: 40%;">Keywords
            <span class="mwc-color-gray-400" mwc-icon mwcIconfont="help-circle"
              style="display: inline-flex;align-items: center;margin-left:5px" mwc-tooltip
              [mwcTooltipTitle]="GKPToolTip" [mwcTooltipTitleContext]="{ $implicit: GKPToolTips.KEYPHRASE_TOOLTIP }"
              mwcTooltipColor="black" mwcTooltipPlacement="top"></span>
          </th>
          <th i18n="@@bs-select-your-keyword-table-col-th-search-vol-text" style="width: 22%;">Search Volume
            <span class="mwc-color-gray-400" mwc-icon mwcIconfont="help-circle"
              style="display: inline-flex;align-items: center;margin-left:5px" mwc-tooltip
              [mwcTooltipTitle]="GKPToolTip" [mwcTooltipTitleContext]="{ $implicit: GKPToolTips.SEARCH_VOLUME_TOOLTIP }"
              mwcTooltipColor="black" mwcTooltipPlacement="top"></span>
          </th>
          <th i18n="@@bs-select-your-keyword-table-col-th-trend-text" style="width: 18%;">Trend
            <span class="mwc-color-gray-400" mwc-icon mwcIconfont="help-circle"
              style="display: inline-flex;align-items: center;margin-left:5px" mwc-tooltip
              [mwcTooltipTitle]="GKPToolTip" [mwcTooltipTitleContext]="{ $implicit: GKPToolTips.TREND_TOOLTIP }"
              mwcTooltipColor="black" mwcTooltipPlacement="top"></span>
          </th>
          <th i18n="@@bs-select-your-keyword-table-col-th-competition-text" style="width: 20%;">Competition
            <span class="mwc-color-gray-400" mwc-icon mwcIconfont="help-circle"
              style="display: inline-flex;align-items: center;margin-left:5px" mwc-tooltip
              [mwcTooltipTitle]="GKPToolTip" [mwcTooltipTitleContext]="{ $implicit: GKPToolTips.COMPETITION_TOOLTIP }"
              mwcTooltipColor="black" mwcTooltipPlacement="top"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of rowSelectionTable.data">
          <td>
            <span>
              <mwc-checkbox-wrapper (mwcOnChange)="keywordSelectionChange(data,$event)">
                <label mwc-checkbox [mwcChecked]="existingKeyword.includes(data.keyPhrase)" [mwcValue]="data.keyPhrase" [mwcDisabled]="((selectedKeywords.length >= maxAllowedKeywordSelection) && (!selectedKeywords.includes(data)) || (existingKeyword.includes(data.keyPhrase)) )">
                  <span class="mwc-text-sm-medium mwc-color-gray-900">{{ data.keyPhrase }}</span>
                </label>
              </mwc-checkbox-wrapper>
            </span>
          </td>
          <td>{{ data.searchVolume }}</td>
          <td>
            <div class="line-graph"><g2-mini-area line borderColor="#12B76A" color="#ECFDF3" height="40"
                [data]="data.trend"></g2-mini-area></div>
          </td>
          <td>
            <mwc-tag [mwcColor]="'primary'">{{ data.competition }}</mwc-tag>
          </td>
        </tr>
      </tbody>
    </mwc-table>
  </div>
</div>
<div *mwcModalFooter>
  <div mwc-row mwcJustify="space-between">
    <div mwc-col mwcSpan="12">
      <button mwc-button mwcType="default" mwcBlock (click)="cancelSelection()"
        [disabled]="(listOfData == null || listOfData == undefined || listOfData?.length == 0)"
        [mwcLoading]="isLoading">Cancel</button>
    </div>
    <div mwc-col mwcSpan="12">
      <button mwc-button mwcType="primary" mwcBlock (click)="addKeywords()"
        [disabled]="(listOfData == null || listOfData == undefined || listOfData?.length == 0)"
        [mwcLoading]="isLoading">Add Keywords</button>
    </div>
  </div>
</div>