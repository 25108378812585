import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { MwcIconService } from 'mwc/icon';
import { CommonService } from 'src/app/services/common/common.service';
import { TokenService } from 'src/app/services/token/token.service';
import { take } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { UserInfo, VerifyUserRequest } from '../models/common';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard {
  _storageHelper: StorageHelper;


  constructor(
    private iconService: MwcIconService,
    private commonService: CommonService,
    private tokenService: TokenService) {
    this._storageHelper = new StorageHelper();
  }

  async canActivate(): Promise<boolean> {
    try {
      const isAuthenticated = await firstValueFrom(this.tokenService.isAuthenticated$);
      await this.tokenService.getMixpanelInfo();
      if (isAuthenticated) {
        this.commonService.getUserInfoValue().subscribe((res: any) => {
          if (res == null) this.commonService.fetchUserInfo().subscribe();
        });
        this.iconService.fetchFromIconfont({ scriptUrl: 'assets/fonts.js' });
        return true;
      }
      await this.tokenService.getMixpanelInfo();
      const request = new VerifyUserRequest();
      request.businessId = +this._storageHelper.user.bId;
      request.profileAlias = this._storageHelper.user.profileAlias;

      const user = await this.tokenService.fetchTokenFromApi(request);

      if (user && user.accessToken) {
        this.commonService.fetchUserInfo().subscribe();
        this.iconService.fetchFromIconfont({ scriptUrl: 'assets/fonts.js' });
        return true;
      } else {
        if (user && user.error && user.error.returnUrl != "") {
          this.tokenService.routeToBase(user.error.returnUrl);
        } else {
          this.tokenService.routeToBase();
        }
        return false;
      }
    } catch (error) {
      console.error('Failed to authenticate:', error);
      this.tokenService.fetchReturnUrl();
      return false;
    }
  }



}
