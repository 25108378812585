import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MwcDrawerRef, MwcDrawerService } from 'mwc/drawer';
import { TopicIdeaWidget } from '../_shared/models/topic';

@Component({
  selector: 'app-secondary-filter',
  templateUrl: './secondary-filter.component.html',
  styleUrls: ['./secondary-filter.component.scss']
})
export class SecondaryFilterComponent {
  intentValue = '';
  searchTxt: string = '';
  @Input() widgetData: TopicIdeaWidget = new TopicIdeaWidget();
  @Input() selectedIntents: string ;
  @Input() selectedTargetAudience: string[] = [];
  allChecked = false;
  indeterminate = true;
  targetAudienceList = [
    //{ label: 'All', value: 'all', checked: true },
    //{ label: 'Outdoor Enthusiasts', value: 'outdoor-enthusiasts', checked: false },
    //{ label: 'Nature Lovers', value: 'nature-lovers', checked: false },
    //{ label: 'Frequent Travelers', value: 'frequent-travelers', checked: false },
    //{ label: 'New to Travel', value: 'new-to-travel', checked: false },
    //{ label: 'Budget-Friendly Travelers', value: 'budget-friendly-travelers', checked: false },
    //{ label: 'Youth Market', value: 'youth-market', checked: false },
    //{ label: 'Individual Travelers', value: 'individual-travelers', checked: false },
    //{ label: 'families', value: 'Families', checked: false },
    //{ label: 'Adventure Seekers', value: 'adventure-seekers', checked: false },
    //{ label: 'Scenic Inspired', value: 'scenic-inspired', checked: false }
  ];
  selectedTargetAudienceList = [];
  constructor(
    private ngChangeDetectorRef: ChangeDetectorRef,
    private drawerRef: MwcDrawerRef<string>) { }

  close(): void {
    this.drawerRef.close({ 'closed': true, 'intent': this.intentValue == "all" ? '' : this.intentValue, 'targetAudience': this.selectedTargetAudience });
  }
  apply(): void {
    this.targetAudienceListChange(this.targetAudienceList);
    this.drawerRef.close({ 'closed': false, 'intent': this.intentValue == "all" ? 'All' : this.intentValue, 'targetAudience': this.selectedTargetAudience });
  }
  clearFilter(): void {
    this.intentValue = '';
    this.targetAudienceList.filter((obj) => (obj.label == 'All') ? obj.checked = false : obj.checked = false);
  }
  targetAudienceListChange(data): void {

    if (this.targetAudienceList.every(item => !item.checked)) {
      this.allChecked = false;
      this.indeterminate = false;
    } else if (this.targetAudienceList.every(item => item.checked)) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
    this.selectedTargetAudience = [];
    this.targetAudienceList.filter(a => a.checked == true).forEach(a => this.selectedTargetAudience.push(a.label));

  }
  triggerSearch() {}
  ngOnInit(): void{
    this.intentValue = this.selectedIntents;
    let tt = this.selectedTargetAudience;
    this.targetAudienceList = [];
    //this.targetAudienceList.push({ label: 'All', value: 'all', checked: true });
    this.widgetData.targetAudiences.forEach((a: any) => {
      let checked = false;
      if (this.selectedTargetAudience != undefined
        && this.selectedTargetAudience.length > 0
        && this.selectedTargetAudience.indexOf(a) !== -1) {
        checked = true;
      }
      this.targetAudienceList.push({ label: a, value: a, checked: checked });
    });
    this.targetAudienceListChange(this.targetAudienceList);
    this.ngChangeDetectorRef.detectChanges();
  }

  updateAllChecked(): void {
    this.indeterminate = false;
    if (this.allChecked) {
      this.targetAudienceList = this.targetAudienceList.map(item => ({
        ...item,
        checked: true
      }));
    } else {
      this.targetAudienceList = this.targetAudienceList.map(item => ({
        ...item,
        checked: false
      }));
    }
  }

  ngOnChanges() {

  }

}
