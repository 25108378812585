import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class MixpanelService {
    private header = new HttpHeaders({ 'Content-Type': 'application/json' });
    constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,) {
    }

    getMixpanelId(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}api/auth/getMixpanelId`, {
            withCredentials: false,
            headers: this.header,
        });
    }
}
