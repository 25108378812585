import { DocumentLanguage, TargetAudienceThingJson, BrandVoiceThingJson } from "./article";
import { topicIdea } from "./topic";

export interface TopicIdeaPayloadForAIGenerationType {
  topicIdea: topicIdea;
  language: string;
  tone: string;
  contentBrief: string;
  gender: string;
  ageGroup: string;
  interestAndPersona: string;
  brandVoiceDocument: string;
  isOutlineCreation: boolean;
  isGenericFlow: boolean;
  templateId: number;
  templateTitle: string;
  inputText: string;
  titleText: string;
  placeholderText: string;
  contentBriefText: string;
  isPublishable: boolean;
}

export class TopicIdeaPayloadForAIGeneration {
  topicIdea: topicIdea = new topicIdea();
  language: string = "";
  tone: string = "";
  contentBrief: string = "";
  gender: string = "";
  ageGroup: string = "";
  interestAndPersona: string = "";
  brandVoiceDocument: string = "";
  targetAudienceDetails: TargetAudienceThingJson;
  brandVoiceDetails: BrandVoiceThingJson;
  isOutlineCreation: boolean = false;
  isGenericFlow: boolean = false;
  HighlightedTextOrCustomPrompt: string = "";
  isExpand: boolean = false;
  isParaphrase: boolean = false;
  isChangeVoice: boolean = false;
  isCustomCommand: boolean = false;
  languageDetail: DocumentLanguage;
  templateId: number;
  targetAudienceId: number;
  brandVoiceId: number;
  templateTitle: string;
  inputText: string;
  titleText: string;
  placeholderText: string;
  contentBriefText: string;
  isPublishable: boolean;

}
