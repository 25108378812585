export class GetSettingsFileRequest {
  ProfileId: number;
  constructor(
    _profileId?: number
  ) {
    this.ProfileId = _profileId;
  }
}

export class GetDatasetFileRequest {
  DatasetId: string;
  constructor(
    _datasetId?: string
  ) {
    this.DatasetId = _datasetId;
  }
}
