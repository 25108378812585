import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, lastValueFrom, Observable, throwError, timeout } from 'rxjs';
import { HelperService } from 'src/app/_shared/services/helper/helper.service';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { GetTopicIdeaListRequest, GetTopicIdeaListResponse, GetTopicIdeaWidgetRequest, GetTopicIdeaWidgetResponse, UpdatePillarIdeaRequest, UpdatePillarIdeaResponse } from '../../_shared/models/topic';
import { GetAudiencesResponseType } from 'src/app/_shared/models/GetAudiencesResponse';
import { GetAudiencesRequestType } from 'src/app/_shared/models/GetAudiencesRequest';


@Injectable({
  providedIn: 'root',
})
export class TopicService {
  // Private Properties  

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private _storageHelper: StorageHelper;
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private helperService: HelperService,    
  ) {
    this._storageHelper = new StorageHelper();
  }


  //Get Topic widget data
  public GetTopicWidget<T>(request: GetTopicIdeaWidgetRequest): Observable<GetTopicIdeaWidgetResponse> {
    return this.http.post<GetTopicIdeaWidgetResponse>(
      `${this.baseUrl}api/Topic/GetTopicWidget`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //GetTopicIdeaList
  public GetTopicIdeaList<T>(request: GetTopicIdeaListRequest): Observable<GetTopicIdeaListResponse> {
    return this.http.post<GetTopicIdeaListResponse>(
      `${this.baseUrl}api/Topic/GetTopicIdeaList`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //UpdatePillarIdea
  public UpdatePillarIdea<T>(request: UpdatePillarIdeaRequest): Observable<UpdatePillarIdeaResponse> {
    return this.http.post<UpdatePillarIdeaResponse>(
      `${this.baseUrl}api/Topic/UpdatePillarIdea`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }

  //Get Target Audiences
  public GetAudiences<T>(request: GetAudiencesRequestType): Observable<GetAudiencesResponseType> {
    return this.http.post<GetAudiencesResponseType>(
      `${this.baseUrl}api/Topic/GetAudiences`,
      JSON.stringify(request),
      { headers: this.headers }
    );
  }
  
}
