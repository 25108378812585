import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ms-dpr-blank-layout',
  template: `<router-outlet></router-outlet>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'mwc-blank-layout'
  }
})
export class BlankLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
