/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { loadTranslations } from '@angular/localize';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { StorageHelper } from './app/_utilities/storage-helper';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
  enableProdMode();
}

// const userLang = navigator.language.split('-')[0];
const _storageHelper = new StorageHelper();
// get language set by user from localstorage 
const userLang = _storageHelper.UserLanguage;
fetch(`assets/i18n/messages.${userLang}.json`)
  .then((response) => response.json())
  .then((translations) => {
    loadTranslations(translations.translations);
    platformBrowserDynamic(providers)
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch((err) => console.error(err));
