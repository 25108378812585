import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TimeoutError } from 'rxjs';
import { ArticleService } from '../../../services/article/article.service';
import { ProfileSelectService } from '../../../services/widgets/profile-select.service';
import { GetProfileListResponse, profileDetail } from '../../../_shared/models/article';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { MwcTableComponent } from 'mwc/table';
import { FEATURES } from '../../../_shared/consts/app-constants';
import { UserInfo } from '../../../_shared/models/common';
import { CommonService } from '../../../services/common/common.service';

export interface Data {
  profileId: number;
  name: string;
  alias: string;
  nickName: string;
  externalAlias: string;
  segment: string;
}
@Component({
  selector: 'app-profile-select',
  templateUrl: './profile-select.component.html',
  styleUrls: ['./profile-select.component.scss']
})
export class ProfileSelectComponent {
  @ViewChild('rowSelectionTable', { static: false }) mwcTableComponent?: MwcTableComponent<profileDetail>;
  @Input() selectedProfileId: any = '';
  listOfData: readonly Data[] = [];
  @Input() profiles: profileDetail[] = [];
  @Input() filteredProfiles: profileDetail[] = [];
  _storageHelper: StorageHelper;


  loading = false;
  listOfColumn = [
    {
      title: 'Profile Name',
      compare: (a: profileDetail, b: profileDetail) => a.name?.localeCompare(b.name),
      priority: false,
      width: '240px'
    },
    {
      title: 'Alias',
      compare: (a: profileDetail, b: profileDetail) => a.alias?.localeCompare(b.alias),
      priority: 3,
      width: '150px'
    },
    {
      title: 'Nick Name',
      compare: (a: profileDetail, b: profileDetail) => a.nickName?.localeCompare(b.nickName),
      priority: 2,
      width: '180px'
    },
    {
      title: 'External Alias',
      compare: (a: profileDetail, b: profileDetail) => a.externalAlias?.localeCompare(b.externalAlias),
      priority: 2,
      width: '180px'
    }
  ];
  constructor(private profileSelectService: ProfileSelectService,
    private articleService: ArticleService,
    private authService: AuthService,
    private router: Router,
    private ngChangeDetectorRef: ChangeDetectorRef,
    private commonService:CommonService) {
    this._storageHelper = new StorageHelper();
  }

  @Output() hideDropdown = new EventEmitter<any>();
  selectProfile(id) {
    this.selectedProfileId = id;
  }
  async applyProfile() {
    this.loading = true;
    var applied = await this.authService.ApplyProfile(this.selectedProfileId);
    if (applied) {
      this.loading = false;
      let currentRoute = this.router.url;
      sessionStorage.removeItem('siteConfig');
      if (this.authService.isAuthenticated()) {
        this.commonService.getUserInfoValue().subscribe((res: UserInfo) => {
          if (res != null) {
            var isContentIntelEnabled = res.featureIds.includes(FEATURES.ContentIntelligenceFeatureName);
            if (isContentIntelEnabled) {
              this.hideDropdown.emit(false);
              this.router.navigateByUrl("dashboard").then(() => {
                if (currentRoute.toLowerCase().includes("dashboard")) {
                  window.location.reload();
                }
              });
            }
            else {
              this.hideDropdown.emit(false);
              this.router.navigateByUrl("manage-content/articles").then(() => {
                if (currentRoute.toLowerCase().includes("manage-content/articles")) {
                  window.location.reload();
                }
              });
            }
          }
        });
      }
    }
    else {
      this.authService.routeToBase();
    }
  }
  closeDropdown() {
    this.hideDropdown.emit(false);
  }
  public searchInProfiles(event) {
    if (event && event.target && event.target.value.length > 0) {
      let value: string = event.target.value;
      this.filteredProfiles = this.profiles.filter((val, index) => {
        return val.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
          val.alias.toLocaleLowerCase().includes(value.toLocaleLowerCase());
      })
    } else {
      this.filteredProfiles = this.profiles;
    }
  }
  trackByIndex(_: number, data: profileDetail): number {
    return data.profileId;
  }
}
