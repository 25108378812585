<div class="profile-select-table">
  <mwc-page-header class="site-page-header">
    <!--title-->
    <mwc-page-header-title><div class="mwc-text-md-medium">Select Profile</div></mwc-page-header-title>
    <mwc-page-header-extra>
      <mwc-input-group [mwcPrefix]="prefixIconSearch" class="mwc-text-sm-regular" style="padding: 8px 14px;">
        <input mwc-input type="text" (keyup)="searchInProfiles($event)" placeholder="Search" mwcSize="small" class="mwc-text-sm-regular"/>
      </mwc-input-group>
      <ng-template #prefixIconSearch>
        <span mwc-icon mwcIconfont="search-lg" style="font-size: 18px; color: #667085;"></span>
      </ng-template>
    </mwc-page-header-extra>
  </mwc-page-header>
  <mwc-table #rowSelectionTable [mwcData]="filteredProfiles" [mwcPaginationAlign]="'right'" [mwcVirtualMinBufferPx]="310" [mwcVirtualMaxBufferPx]="310" mwcClassName="" [mwcShowPagination]="'false'" [mwcFrontPagination]="false" [mwcVirtualItemSize]="30" [mwcVirtualForTrackBy]="trackByIndex" class="profile-table" [mwcScroll]="{ y: '310px' }" [mwcLoading]="loading">
    <thead>
      <tr>
        <!-- <th *ngFor="let column of listOfColumn"  [mwcSortFn]="column.compare" [mwcSortPriority]="column.priority" [ngStyle]="{'width': column.width}">
          {{ column.title }}
        </th> -->
        <ng-container *ngFor="let column of listOfColumn ; let i = index" >
          <th [mwcSortFn]="column.compare" [mwcSortPriority]="column.priority" [mwcWidth]="column.width">{{ column.title }}</th>
        </ng-container>
      </tr>
    </thead>
    <tbody id="profileTable">
      <ng-template mwc-virtual-scroll let-data let-index="index">
        <tr>
          <td>
            <span>
              <mwc-radio-group [(ngModel)]="selectedProfileId" (ngModelChange)="selectProfile($event)">
                <label mwc-radio [mwcValue]="data.profileId" class="mwc-text-sm-medium">
                  <span class="mwc-text-sm-medium mwc-color-gray-900 profile-text" mwc-typography mwcEllipsis>
                    {{ data.name }}
                  </span>
                </label>
              </mwc-radio-group>
            </span>
          </td>
          <!-- <td>
            <span class="profile-name">{{ data.profileName }}</span>
          </td> -->
          <td class="mwc-text-xs-regular">{{ data.alias }}</td>
          <td class="mwc-text-sm-regular"><span mwc-typography mwcEllipsis>{{ data.nickName }}</span></td>
          <td class="mwc-text-sm-regular"><span mwc-typography mwcEllipsis>{{ data.externalAlias }}</span></td>
          <!--<td>{{ data.segment }}</td>-->
        </tr>
      </ng-template>
    </tbody>
  </mwc-table>
  <div class="profile-action">
    <button mwc-button mwcType="link" mwcSize="small" (click)="closeDropdown()">Cancel</button>
    <button mwc-button mwcType="primary" mwcSize="small" (click)="applyProfile()">Apply</button>
  </div>
</div>
