<mwc-tabset>
    <mwc-tab [mwcTitle]="all" (mwcClick)="onTabSelect('all')"></mwc-tab>
    <mwc-tab [mwcTitle]="web" (mwcClick)="onTabSelect('web')"></mwc-tab>
    <mwc-tab [mwcTitle]="news" (mwcClick)="onTabSelect('news')"></mwc-tab>
    <mwc-tab [mwcTitle]="social" (mwcClick)="onTabSelect('social')"></mwc-tab>
    <mwc-tab [mwcTitle]="media" (mwcClick)="onTabSelect('media')"></mwc-tab>
    <mwc-tab [mwcTitle]="advertising" (mwcClick)="onTabSelect('advertising')"></mwc-tab>
    <mwc-tab [mwcTitle]="emails" (mwcClick)="onTabSelect('emails')"></mwc-tab>
    <ng-template #all> <span class="mwc-text-sm-medium">All Templates </span> <mwc-tag
            [mwcColor]="tabs['all'] ? 'primary' : 'gray'">{{templatesCount['all']}}</mwc-tag></ng-template>
    <ng-template #web> <span class="mwc-text-sm-medium"> Web </span> <mwc-tag
            [mwcColor]="tabs['web'] ? 'primary' : 'gray'">{{templatesCount['web']}}</mwc-tag></ng-template>
    <ng-template #news> <span class="mwc-text-sm-medium"> News </span> <mwc-tag
            [mwcColor]="tabs['news'] ? 'primary' : 'gray'">{{templatesCount['news']}}</mwc-tag></ng-template>
    <ng-template #social> <span class="mwc-text-sm-medium"> Social </span> <mwc-tag
            [mwcColor]="tabs['social'] ? 'primary' : 'gray'">{{templatesCount['social']}}</mwc-tag></ng-template>
    <ng-template #media> <span class="mwc-text-sm-medium"> Media </span> <mwc-tag
            [mwcColor]="tabs['media'] ? 'primary' : 'gray'">{{templatesCount['media']}}</mwc-tag></ng-template>
    <ng-template #advertising> <span class="mwc-text-sm-medium"> Advertising </span> <mwc-tag
            [mwcColor]="tabs['advertising'] ? 'primary' : 'gray'">{{templatesCount['advertising']}}</mwc-tag></ng-template>
    <ng-template #emails> <span class="mwc-text-sm-medium"> Emails </span> <mwc-tag
            [mwcColor]="tabs['emails'] ? 'primary' : 'gray'">{{templatesCount['emails']}}</mwc-tag></ng-template>
</mwc-tabset>
<mwc-spin [mwcSpinning]="isTemplateLoading">
    <cdk-virtual-scroll-viewport #templateContainer itemSize="217" class="templates-container"
        style="height: {{containerHeight}}">

        <div class="templates-row" *cdkVirtualFor="let row of rowedData">
            <div class="template-card" (click)="onSelect(template)" *ngFor="let template of row"
                >
                <div class="get-started-overlay">
                    <button mwc-button mwcType="primary" mwcSize="small" (click)="initializeNewArticle('AI', template)">
                      <span mwc-icon mwcIconfont="stars-02" style="font-size: 16px;"></span>
                      <span class="mwc-text-sm-medium">Get Started</span>
                    </button>
                  </div>
                <mwc-tag *ngIf="template.isRecommended" class="tag" mwcColor="primary">Recommended</mwc-tag>
                <div class="template-card-heading">
                    <div class="featured-icon {{template.iconColor}}">
                        <span mwc-icon mwcIconfont={{template.icon}}></span>
                    </div>
                    <div class="template-card-title mwc-text-md-medium mwc-color-gray-800">{{template.title}}</div>
                </div>
                <div class="mwc-text-xs-regular mwc-color-gray-600">
                    {{template.description}}
                </div>
            </div>
        </div>

    </cdk-virtual-scroll-viewport>
</mwc-spin>
