import { LOCAL_STORAGE_KEYS } from '../_shared/consts/app-constants';
import { User } from '../_shared/models/common';
import { PageCategory, SiteConfig } from '../_shared/models/cms';

export class StorageHelper {
  private get(key: string) {
    const val = localStorage.getItem(key);
    if (val) return JSON.parse(val);
  }

  private set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  clear() {
    localStorage.clear();
  }

  get user(): User {
    return this.get(LOCAL_STORAGE_KEYS.User);
  }

  set user(tokenData: User) {
    this.set(LOCAL_STORAGE_KEYS.User, tokenData);
  }


  get siteConfig(): SiteConfig {
    return this.get(LOCAL_STORAGE_KEYS.SiteConfig);
  }

  set siteConfig(config: SiteConfig) {
    this.set(LOCAL_STORAGE_KEYS.SiteConfig, config);
  }


  get siteModule(): PageCategory[] {
    return this.get(LOCAL_STORAGE_KEYS.SiteModule);
  }

  set siteModule(category: PageCategory[]) {
    this.set(LOCAL_STORAGE_KEYS.SiteModule, category);
  }


  get UserLanguage(): string {
    return this.get(LOCAL_STORAGE_KEYS.UserLanguage) ? this.get(LOCAL_STORAGE_KEYS.UserLanguage) : "en";
  }

  set UserLanguage(value: string) {
    this.set(LOCAL_STORAGE_KEYS.UserLanguage, value);
  }

  // Generic method to update specific fields in the user object
  private updateUser(partialUser: Partial<User>) {
    let currentUser = this.user;

    if (!currentUser) {
      // Initialize default user object
      currentUser = {
        success: false,
        message: '',
        accessToken: '',
        userId: 0,
        userName: '',
        returnUrl: '',
        bId: '',
        damApiUrl: '',
        isDamEnabled: false,
        pId: 0,
        industryName: '',
        industryId: '',
        isCmsEnabled: false,
        profileName: '',
        cmsSiteUrl: '',
        //isContentIntelEnabled: false,
        //isContentGenEnabled: false,
        isAgencyUser: false,
        businessName: '',
        businessAlias: '',
        profileAlias: '',
        dprBaseUrl: '',
        businessThingId: '',
        userThingId: '',
        expiryTimestamp: 0,
        brandVoiceUrl: '',
        targetAudienceUrl: '',
        businessInformationUrl : ''
      } as User;
    }

    // Update user with new partial fields
    this.user = { ...currentUser, ...partialUser };
  }

  // Method to update businessId in user object
  setUserBusinessId(businessId: string) {
    this.updateUser({ bId: businessId });
  }

  // Method to update profileAlias in user object
  setUserProfileAlias(profileAlias: string) {
    this.updateUser({ profileAlias: profileAlias });
  }
  
}
