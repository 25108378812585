import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MwcMessageService } from 'mwc/message';
import { MwcModalRef, MwcModalService, NZ_MODAL_DATA } from 'mwc/modal';
import { TimeoutError } from 'rxjs';
import { CreateNewModalComponent } from 'src/app/manage-content/articles/create-new-modal/create-new-modal.component';
import { SelectYourKeywordComponent } from 'src/app/manage-content/articles/select-your-keyword/select-your-keyword.component';
import { ErrorModalOrigins } from 'src/app/_shared/consts/article-constants';
import { CustomArticleErrorModel } from 'src/app/_shared/models/article';
import { ArticleHelperService } from 'src/app/_shared/services/helper/article/article-helper.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { GetSettingsFileRequest } from 'src/app/_shared/models/getsettingsfilerequest';
import { GetSettingsFileResponse } from 'src/app/_shared/models/getsettingsfileresponse';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { TopicIdeaPayloadForAIGenerationType } from 'src/app/_shared/models/TopicIdeaPayloadForAIGeneration';
import { GenarateAiComponent } from 'src/app/genarate-ai-modal/genarate-ai.component';

@Component({
  selector: 'app-article-error-modal',
  templateUrl: './article-error-modal.component.html',
  styleUrls: ['./article-error-modal.component.scss']
})
export class ArticleErrorModalComponent {
  readonly mwcModalData = inject(NZ_MODAL_DATA);
  public errorHeading: string = $localize`:@@bs-error-modal-error-heading-text:Oops, Something Went Wrong!`;
  public errorSubHeading: string = $localize`:@@bs-error-modal-error-subheading-text:Looks like something went wrong. Let's try that again.`;
  settings: GetSettingsFileResponse;
  isErrorDetailsBoxVisisble = false;
  _storageHelper: StorageHelper;
  constructor(private modalService: MwcModalService, private modal: MwcModalRef, 
    private router: Router, private articleHelperService: ArticleHelperService, private settingsService: SettingsService, private msg: MwcMessageService) {
      this._storageHelper = new StorageHelper();
    }
  
  async ngOnInit() {
    //get settings here
    await this.getSettings();
    // if cutom error is set 
    if (this.mwcModalData.customError) {
      let customError: CustomArticleErrorModel = this.mwcModalData.customError;
      this.errorHeading = customError.ErrorHeading;
      this.errorSubHeading = customError.ErrorSubHeading;
    }
    document.getElementById("error-details").innerHTML = "";
    if (this.mwcModalData.exception) {
      // set exception in error div
      if (this.mwcModalData.exception instanceof Error ||
        this.mwcModalData.exception instanceof TimeoutError
      ) {
        document.getElementById("error-details").innerHTML = `<code>${this.mwcModalData.exception.stack}</code>`;
      }
      if(this.mwcModalData.exception instanceof HttpErrorResponse){
        document.getElementById("error-details").innerHTML = `<code>${JSON.stringify(this.mwcModalData.exception)}</code>`;
      }
    }
  }
  public getSettings() {
    let getSettingsFileRequest = new GetSettingsFileRequest();
    getSettingsFileRequest.ProfileId = this._storageHelper.user.pId; //as of no i set centria profileId once profile dropdown ready with replace this code.
    this.settingsService.getSettings<GetSettingsFileRequest>(getSettingsFileRequest).subscribe((response: GetSettingsFileResponse) => {
      this.settings = response;
    });
  }

  public hideErrorPopup() {
    this.modal.destroy();
  }
  
  public onTryAgainClicked(): void {
    // switch based on screen error came from
    let modalOrigin = this.mwcModalData.modalOrigin;
    switch (modalOrigin) {
      case ErrorModalOrigins.FROM_KEYPHRASE_GENERATION_MODAL:
        // destroy error modal
        this.hideErrorPopup();
        // call keyphrase generation modal
        //this.articleHelperService.createnewpost("AI", this.router, this.modalService, this.settings);
      break;
      case ErrorModalOrigins.FROM_GKP_MODAL:
        // destroy error modal
         this.hideErrorPopup();
        //for now call keyphrase generation modal TODO: call gkp modal with existing data 
        this.articleHelperService.createnewpost("AI", this.router, this.modalService, this.settings);
      break;
      case ErrorModalOrigins.FROM_TOPIC_IDEA_AI_GENERATION_MODAL:
        // destroy error modal
        this.hideErrorPopup();
        // check if topicIdeaPayloadForAIGeneration is not null. if not again invoke generate with AI modal for the same
        if (this.mwcModalData.topicIdeaPayloadForAIGeneration) {
          this.modalService.create({
            mwcData: {
              AIData: this.mwcModalData.topicIdeaPayloadForAIGeneration
            },
            mwcWidth: '800px',
            mwcCentered: true,
            mwcContent: GenarateAiComponent,
            mwcClassName: 'generate-ai-modal'
          });
        } else {
          this.msg.error("Cannot initialize prompt modal!!")
          console.log("Cannot initialize prompt modal!! reason is below");
          console.log(this.mwcModalData.topicIdeaPayloadForAIGeneration);
        }
        //for now call keyphrase generation modal TODO: call gkp modal with existing data 
        // this.modalService.create({
        //   mwcData: {
        //     description: ''
        //   },
        //   mwcWidth: '640px',
        //   mwcContent: CreateNewModalComponent
        // });
      break;
      case ErrorModalOrigins.DEFAULT:
      // TODO: in case if we implement in future
      break;
      default:
        break;
    }

  }

  public onErrorDetailsClicked() {
    // display error message box if it is not visible
    if (this.isErrorDetailsBoxVisisble) {
      document.getElementById("error-details").style.display = "none";
      this.isErrorDetailsBoxVisisble = false;
    } else {
      document.getElementById("error-details").style.display = "block";
      this.isErrorDetailsBoxVisisble = true;
    }
  }
}
