import { Guid } from "guid-typescript";
import { DamAssetInfo, ManualMeta } from "../modules/shared-ui/models/damassetinfo";
import { ImageTransformations } from "../modules/shared-ui/models/imagetransformations";

export class AssetPopupInputModel {
  assetType: string;
}
export class DamPopupModel {
  businessIds: string;
  resourceTypeId?: number;
  assetTransforms: string;
  assetFilters: string;
  assetBrowserUIConfig: string;
  multiSelection: boolean;
  showMultipleImages: boolean;
}

export class DamAssetModelRquest {
  AssetId?: Guid;
  AssetName: string;
  AssetAlias: string;
  FolderPath: string;
  FolderId: number;
  StockImage: boolean;
  ManualMeta: object;
  DamAssetInfo?: object;
  ImageTransformations?: object;
}
export class DamAssetModelResponse {
  assetId?: Guid;
  assetName: string;
  assetAlias: string;
  folderPath: string;
  folderId: number;
  stockImage: boolean;
  manualMeta: object;
  damAssetInfo?: object;
  imageTransformations?: object;
}
