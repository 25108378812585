<div class="basic-layout">
    <div class="outer-layout">
        <div class="inner-layout">
            <img src={{presetData?.imgPath}} alt="" class="image-layout">
            <div class="text-layout">
                <span class="mwc-text-lg-medium mwc-color-gray-900">{{presetData?.title}}</span>
                <ng-container *ngIf="!isObject">
                    <span class="mwc-text-sm-regular mwc-color-gray-600">
                        {{presetData?.description}}
                    </span>
                </ng-container>
                <ng-container *ngIf="isObject">
                    <ng-container *mwcStringTemplateOutlet="presetData.description">{{ presetData?.description}}</ng-container>
                </ng-container>
            </div>
        </div>
        <div class="button-layout" *ngIf="presetData?.buttonStr && presetData?.buttonStr.length > 0">
            <div *ngFor="let item of presetData.buttonStr">
                <button mwc-button [mwcType]="item?.type ? item.type :'primary'" mwcSize="small"
                    (click)="onBtnClick(item.button)"><span mwc-icon *ngIf="item?.icon" [mwcIconfont]="item?.icon" style="font-size: 16px;"></span>{{item?.button}}</button>
            </div>
        </div>
    </div>
</div>