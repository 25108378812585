import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'mwc-empty-state',
  templateUrl: './mwc-empty-state.component.html',
  styleUrls: ['./mwc-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MwcEmptyStateComponent {
  @Input() presetData: { title: string, description: string, imgPath: string | object, buttonStr?:any } = { 'title': 'No Data', 'description': '', 'imgPath': 'assets/images/no-article-found.png'};
  @Output() clickEvent = new EventEmitter()
  isObject:boolean = false;
  ngOnChanges() {
    if (typeof (this.presetData?.description) == 'object') {
      this.isObject = true;
    }
  }
  onBtnClick(item) {
    this.clickEvent.emit(item);
  }
}
