import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MwcModalRef, MwcModalService } from 'mwc/modal';
import { Subject } from 'rxjs';
import { TemplateSelectorComponent } from 'src/app/_shared/modules/shared-ui/components/template-selector/template-selector.component'
import { topicIdea } from '../../../models/topic';
@Injectable({
    providedIn: 'root'
})
export class TemplateHelperService {

    private modalService;
    private templateTile;
    private modalRef;

  openTemplateSelector(data: topicIdea = null, textTitle:string = null) {
      this.createTemplate(data, textTitle);
    }

  init(modalService: MwcModalService, templateTitle) {
    this.modalService = modalService;
    this.templateTile = templateTitle;
  }

  closeTemplateSelector() {
    if (this.modalRef)
      this.modalRef.close();
  }

  createTemplate(data: topicIdea = null, textTitle: string = null) {
    this.modalRef = this.modalService.create({
          mwcData: {
            topicIdeaData: data,
            textTitle: textTitle
          },
          mwcTitle: this.templateTile,
          mwcWidth: '1130px',
          mwcCentered: true,
          mwcComponentParams: {
            containerHeight: '60vh'
          },
          mwcContent: TemplateSelectorComponent,
          mwcClassName: 'template-service-modal',
          mwcFooter:null
        });
      }
}
