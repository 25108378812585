import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateSelectorComponent } from './template-selector.component';
import { MwcTabsModule } from 'mwc/tabs';
import { MwcTagModule } from 'mwc/tag';
import { MwcIconModule } from 'mwc/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MwcSpinModule } from 'mwc/spin';
import { MwcButtonModule } from 'mwc/button';



@NgModule({
  declarations: [TemplateSelectorComponent],
  imports: [
    CommonModule,
    MwcTabsModule,
    MwcTagModule,
    MwcIconModule,
    ScrollingModule,
    MwcButtonModule,
    MwcSpinModule
  ],
  exports: [TemplateSelectorComponent]
})
export class TemplateSelectorModule { }
