//Get Topic Idea List
export class GetTopicIdeaListRequest {
  profileId: number = 0;
  topicType: string = "New";
  coreTopic: string = "";
  intent: string = "";
  isDismissed: boolean | null = null;
  isFavourite: boolean | null = null;
  isDone: boolean | null = null;
  targetAudience: string[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  orderBy: string = "searchVol";
  sortingOrder: string = "desc";
}
export class GetTopicIdeaListResponse {

  topicIdeas: topicIdea[];
  totalRecords: number;
  errorCode?: number;
  message: string;
}
export class topicIdea {
  _id: string;
  profileId: number;
  topicType: string;
  coreTopic: string;
  pillarIdea: string;
  keywords: string[];
  clitPos: string;
  c1Pos: string;
  c2Pos: string;
  c3Pos: string;
  cliURL: string;
  c1URL: string;
  c2URL: string;
  c3URL: string;
  searchVol: number;
  trafficShare: number;
  trafficOpp: number;
  diff: string;
  cpc: string;
  trend: number[];
  intent: string;
  audience: string[];
  isDismissed: boolean;
  isFavourite: boolean;
  isDone: boolean;
}

//GetWidget Data for Topic Idea
export class TopicIdeaWidget {
  totalIdeas: number;
  searchVol: number;
  trafficshare: number;
  trafficOpp: number;
  intents: string[];
  targetAudiences: string[];
}
export class PillarAggregatedData {
  profileId: number;
  typeNew: TopicIdeaWidget;
  typeCompetitive: TopicIdeaWidget;
  typeRichResult: TopicIdeaWidget;
  targetAudiences: string[] = [];
}
export class GetTopicIdeaWidgetRequest {
  profileId: number;
  topicType: string = "New";
}
export class GetTopicIdeaWidgetResponse {
  pillarAggregatedData: PillarAggregatedData;
  errorCode?: number;
  message: string;
}

export class UpdatePillarIdeaRequest {
  id: string;
  actionType: string;
  actionValue: boolean;
}
export class UpdatePillarIdeaResponse {  
  errorCode?: number;
  message: string;
}
