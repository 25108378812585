import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BasicLayoutComponent } from './basic-layout/basic-layout.component';
import { PassportLayoutComponent } from './passport-layout/passport-layout.component';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { MwcLayoutModule } from 'mwc/layout';
import { MwcIconModule } from 'mwc/icon';
import { MwcMenuModule } from 'mwc/menu';
import { MwcSelectModule } from 'mwc/select';
import { FormsModule } from '@angular/forms';
import { MwcTagModule } from 'mwc/tag';
import { MwcAvatarModule } from 'mwc/avatar';
import { MwcDropDownModule } from 'mwc/dropdown';
import { MwcButtonModule } from 'mwc/button';
import { ProfileSelectComponent } from './widget/profile-select/profile-select.component';
import { MwcTableModule } from 'mwc/table';
import { MwcPageHeaderModule } from 'mwc/page-header';
import { MwcInputModule } from 'mwc/input';
import { ProfileSelectService } from '../services/widgets/profile-select.service'
import { MwcRadioModule } from 'mwc/radio';
import { MwcTypographyModule } from 'mwc/typography';
import { MwcPopoverModule } from 'mwc/popover';
import { MwcModalModule } from 'mwc/modal';
import { MwcGridModule } from 'mwc/grid';
import { MwcToolTipModule } from 'mwc/tooltip';

const COMPONENTS = [
  BasicLayoutComponent,
  PassportLayoutComponent,
  BlankLayoutComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ProfileSelectComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    MwcLayoutModule,
    MwcMenuModule,
    MwcIconModule,
    MwcSelectModule,
    MwcTagModule,
    MwcAvatarModule,
    MwcDropDownModule,
    MwcButtonModule,
    MwcTableModule,
    MwcPageHeaderModule,
    MwcInputModule,
    MwcRadioModule,
    MwcTypographyModule,
    MwcPopoverModule,
    MwcModalModule,
    MwcGridModule,
    MwcToolTipModule
  ],
  providers: [ProfileSelectService],
  exports: [
    ...COMPONENTS
  ]
})
export class LayoutModule { }
