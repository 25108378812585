import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor() {
    
   }
  public localize(stringToLocalize: any, id: any, meaning: string = "", description: string = "") {
    let data = `:`;
    if (meaning && meaning.length > 0) {
      // meaning available
      data += `${meaning}|`;
    }
    if (description && description.length > 0) {
      // description available
      data += `${description}`;
    }
    // attach id at the end
    data += `${id}:${stringToLocalize}`;
    return `${data}`;
  }
}
