<div class="parent-modal">
    <div class="empty-status-body">
        <div class="empty-status">
          <div class="empty-status-msg">
            <div class="image-center">
              <img src="assets/images/Illustration-warning.svg" style="width: 152px;height: 118px;">
            </div>
            <div>
              <div class="mwc-text-lg-semibold mwc-color-gray-900">{{ errorHeading }}</div>
              <span mwc-typography mwcType="secondary" style="display:block;width: 450px;" class="mwc-text-sm-regular">{{ errorSubHeading }}</span>
            </div>
          </div>
          <div>
            <button i18n="@@bs-error-modal-try-again-text" mwc-button mwcType="primary" (click)="onTryAgainClicked()">Try Again</button>
            <button i18n="@@bs-error-modal-details-button-text" mwc-button mwcType="default" style="margin-left: 10px;" mwcDanger (click)="onErrorDetailsClicked()">Details</button>
          </div>
        </div>
        <div id="error-details" class="error-details">
          <p>test</p>
        </div>
    </div>
</div>