import { ChangeDetectorRef, Component, ElementRef, InjectFlags, Input, Optional, TemplateRef, ViewChild, inject } from '@angular/core';
import { forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ArticleService } from 'src/app/services/article/article.service';
import { Template, GetTemplateResponse } from 'src/app/_shared/models/article';
import { Router } from '@angular/router';
import { MwcModalService } from 'mwc/modal';
import { GetSettingsFileResponse } from 'src/app/_shared/models/getsettingsfileresponse';
import { ArticleHelperService } from 'src/app/_shared/services/helper/article/article-helper.service';
import { NZ_MODAL_DATA } from 'mwc/modal';
import { topicIdea } from 'src/app/_shared/models/topic';
import { GetSettingsFileRequest } from 'src/app/_shared/models/getsettingsfilerequest';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { UserInfo } from 'src/app/_shared/models/common';
import { CommonService } from 'src/app/services/common/common.service';
import { ContentStudioTrackingService } from 'src/app/_shared/services/tracking/contentstudio-tracking.service';

//export interface Template {
//  title: string;
//  isRecommended?: boolean;
//  description: string;
//  iconColor: 'primary' | 'green' | 'yellow';
//  type: 'web' | 'news' | 'social' | 'media' | 'advertising' | 'emails';
//  id?: number;
//}

@Component({
  selector: 'app-template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss']
})
export class TemplateSelectorComponent {
  @Input() containerHeight = 'calc(100vh - 308px)';
  @ViewChild('templateContainer', { read: ElementRef }) templateContainer;
  tabs = { 'all': true, 'web': false, news: false, social: false, media: false, advertising: false, emails: false };
  templatesCount = {};
  templateTypes = ['web', 'news', 'social', 'media', 'advertising', 'emails'];
  isTemplateLoading: boolean = false;
  settings: GetSettingsFileResponse;
  readonly mwcModalData: { topicIdeaData: topicIdea, textTitle: string } = inject(NZ_MODAL_DATA, InjectFlags.Optional);
  _storageHelper: StorageHelper;
  rowedData = [];
  allTemplates = [];
  selectedCard;
  topicData = this.mwcModalData?.topicIdeaData ? this.mwcModalData.topicIdeaData : null;
  textTitle = this.mwcModalData?.textTitle ? this.mwcModalData.textTitle : "";
  isSaasUser: boolean = false;
  private destroy$: Subject<any> = new Subject();

  constructor(
    private tracking: ContentStudioTrackingService,
    private articleService: ArticleService,
    private modalService: MwcModalService,
    private _router: Router,
    private ngChangeDetectorRef: ChangeDetectorRef,
    private articleHelperService: ArticleHelperService,
    private settingsService: SettingsService, private commonService: CommonService) {
    this._storageHelper = new StorageHelper();
  }

  onTabSelect(tabName: string) {
    for (let k in this.tabs) {
      this.tabs[k] = false;
    }
    this.tabs[tabName] = true;
    this.rowedData = this.distributeToRows(tabName === 'all' ? this.allTemplates : this.allTemplates.filter(t => t.type === tabName));
    this.templateContainer.nativeElement.scrollTop = 0;
  }

  //getDummyData(count) {
  //  let temp: Template = {
  //    title: 'Blog Article',
  //    description: 'Turn a title and an outline into a fully SEO-optimized and long article with this AI editor.',
  //    isRecommended: true,
  //    iconColor: 'primary',
  //    type: 'web'
  //  };
  //  let colors = ['primary', 'green', 'yellow'];
  //  let type = ['web', 'news', 'social', 'media', 'advertising', 'emails']

  //  let templates = [];

  //  for (let i = 0; i < count; i++) {
  //    templates.push({ ...temp, id: i, 
  //      title: temp.title + i, 
  //      iconColor: colors[Math.floor(Math.random() * 3)],
  //      type: type[Math.floor(Math.random() * 6)],
  //      isRecommended:  !!(Math.floor(Math.random() * 2))
  //    });
  //  }

  //  return templates;
  //}

  distributeToRows(data) {
    let distributed = [];
    let row = [];
    data.forEach((datum, i) => {
      if (i && i % 4 == 0) {
        distributed.push([...row]);
        row = [];
      }
      row.push(datum);
    });
    distributed.push([...row]);
    return distributed;
  }

  onSelect(card) {
    this.selectedCard = card;
  }

  initializeNewArticle(mode: string, templateData: Template) {
    this.tracking.trackEvent(
      'pageview', `Template - ${templateData.title}`
    );

    if (templateData.templateId === 0) {
      mode = 'mannual';
    }
    this.articleHelperService.createnewpost(mode, this._router, this.modalService, this.settings, this.topicData, templateData, this.textTitle);
  }

  //getTemplates() {
  //  of(this.getDummyData(178)).subscribe(templates => {
  //    this.allTemplates = [...templates];
  //    this.templatesCount['all'] = this.allTemplates.length;
  //    this.templateTypes.forEach(type => {
  //      this.templatesCount[type] = this.allTemplates.filter(t => t.type === type).length;
  //    });
  //    this.selectedCard = templates[0];
  //    this.rowedData = this.distributeToRows([...templates]);
  //  });
  //}

  getTemplatesAndSettings() {
    this.isTemplateLoading = true;
    const getTemplatesRequest = this.articleService.GetTemplateList<GetTemplateResponse>();
    let getSettingsFileRequest = new GetSettingsFileRequest();
    getSettingsFileRequest.ProfileId = this._storageHelper.user.pId;
    const getSettingsRequest = this.settingsService.getSettings<GetSettingsFileRequest>(getSettingsFileRequest);
    forkJoin({
      templatesResponse: getTemplatesRequest,
      settingsResponse: getSettingsRequest
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ templatesResponse, settingsResponse }) => {
        this.isTemplateLoading = false;
        if (templatesResponse) {
          this.allTemplates = [...templatesResponse.templates];
          this.templatesCount['all'] = templatesResponse.totalRecords;
          this.templateTypes.forEach(type => {
            this.templatesCount[type] = this.allTemplates.filter(t => t.type === type).length;
          });
          //this.selectedCard = templatesResponse.templates[0];
          this.rowedData = this.distributeToRows([...templatesResponse.templates]);
        }
        if (settingsResponse) {
          this.settings = settingsResponse;
        }
        this.ngChangeDetectorRef.detectChanges();
      });
  }

  ngOnInit() {
    this.getTemplatesAndSettings();
    this.commonService.getUserInfoValue().subscribe((res: UserInfo) => {
      if (res != null) {
        this.isSaasUser = res.isSaasUser;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
