import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openComponentDialog(
    componentRef: ComponentType<unknown>,
    config?: MatDialogConfig
  ): MatDialogRef<unknown, unknown> {
    return this.dialog.open(componentRef, config);
  }
}
