import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ArticleListTableTabs } from 'src/app/_shared/consts/article-constants';
import {
  Article, ArticleDetail, GetSEOWidgetResponse,
  SEOWidgetStoreState, SEOWidgetStoreStateType
} from 'src/app/_shared/models/article';

@Injectable({
  providedIn: 'root'
})
export class ArticleStoreService {
  // isparent article
  private isParentArticle = new BehaviorSubject<boolean>(true);
  isParent = this.isParentArticle.asObservable();

  // single store state
  private state = new BehaviorSubject<Article>(new Article());
  currentState = this.state.asObservable();

  // list state
  private listState = new BehaviorSubject<ArticleDetail[]>([]);
  public currentListState = this.listState.asObservable();

  // list loading
  private isListLoadingState = new BehaviorSubject<boolean>(false);
  public currentIsListLoadingState = this.isListLoadingState.asObservable();

  // list enabled tab
  private listEnabledTabState = new BehaviorSubject<ArticleListTableTabs>(ArticleListTableTabs.ALL);
  public currentListEnabledTabState = this.listEnabledTabState.asObservable();

  // performance widget data
  private seoWidgetState = new BehaviorSubject<SEOWidgetStoreStateType>(new SEOWidgetStoreState());
  public currentSEOWidgetState = this.seoWidgetState.asObservable();

  // performance widget data
  private listSearchTextState = new BehaviorSubject<string>("");
  public currentListSearchTextState = this.listSearchTextState.asObservable();

  //list length
  private listLengthState = new BehaviorSubject<number>(1);
  public currentListLengthState = this.listLengthState.asObservable();


  // Total Records
  private totalrecords = new BehaviorSubject<number>(0);
  public currentTotalrecords = this.totalrecords.asObservable();

  // selected SEO Widget Changed
  private selectedSEOWidgetChanged = new BehaviorSubject<Boolean>(false);
  public currentSelectedSEOWidgetChanged = this.selectedSEOWidgetChanged.asObservable();

  // selected Article status tab Changed
  private selectedArticleStatusTabChanged = new BehaviorSubject<Boolean>(false);
  public currentSelectedArticleStatusTabChanged = this.selectedArticleStatusTabChanged.asObservable();

  constructor() { }

  // update single state
  public updateState(article: Article) {
    this.state.next(article)
  }

  // update list state
  public updateListState(list: ArticleDetail[],totalRecords:number,isSEOWidgetChanged:Boolean,isSelectedArticleStatusTabChanged:Boolean) {
    this.listState.next(list)
    this.totalrecords.next(totalRecords);
    this.selectedSEOWidgetChanged.next(isSEOWidgetChanged);
    this.selectedArticleStatusTabChanged.next(isSelectedArticleStatusTabChanged);
  }

  public updateIsParentArticle(isParent: boolean) {
    this.isParentArticle.next(isParent);
  }

  // update list loading
  public updateIsListLoadingState(isLoading: boolean) {
    this.isListLoadingState.next(isLoading);
  }

  // update list enabled tab
  public updateListEnabledTabState(listEnabledTabState: ArticleListTableTabs) {
    this.listEnabledTabState.next(listEnabledTabState);
  }

  public updateSEOWidgetState(seoWidgetData: SEOWidgetStoreStateType) {
    this.seoWidgetState.next(seoWidgetData);
  }

  // list search text state
  public updateListSearchTextState(searchText: string) {
    this.listSearchTextState.next(searchText);
  }

  // update list length state
  public updateListLengthState(length: number) {
    this.listLengthState.next(length);
  }

  private seoRange: number = 0;
  setSEORange(range: number): void {
    this.seoRange = range;
  }

  getSEORange(): number {
    return this.seoRange;
  }
}
