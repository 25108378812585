import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  _clone(data: any) {
    try {
      return JSON.parse(JSON.stringify(data));
    } catch (e) {
      return data;
    }
  }

  public async isValidImageUrl(url: string): Promise<Boolean> {
    let image = new Image()
    image.src = url
    return await (new Promise((resolve)=>{
      image.onload = function () {
        if (image.height === 0 || image.width === 0) {
          resolve(false);
          return;
        }
        resolve(true)
      }
      image.onerror = () =>{
        resolve(false)
      }
    }))
  }
  public generateMD5Hash(content: string) {
    return Md5.hashStr(content);
  }

  public compareMD5Hash(hash1: string, hash2: string) {
    if (hash1.length > 0 && hash2.length > 0) {
      if (hash1 !== hash2) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public makeFirstLetterCapital(text: string) {
    if (text && text.length > 0) {
      return text[0].toLocaleUpperCase() + text.substring(1, text.length);
    }
    return "";
  }
}
